import styled from "styled-components"

export const Wrapper = styled.div`
  color: #616161;

  h3,
  h4 {
    font-family: ${props => props.theme.font.name.primary};
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 24px;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
  }

  h4 {
    color: ${props => props.theme.colors.secondary};
  }

  p {
    margin-bottom: 20px;
    line-height: 26px;
    text-align: justify;

    strong,
    b {
      font-weight: 400;
    }
  }

  a {
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
  }

  table {
    border: 1px solid silver;
    width: 100%;

    td,
    th {
      border: 1px solid silver;

      span {
        display: block;
      }
      div {
        padding-bottom: 10px;
      }
    }
  }

  ul {
    list-style: square;
    margin-left: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  ul.star {
    list-style: none;
    li {
      margin-bottom: 15px;
      &:before {
        content: "★";
        display: inline-block;
        padding: 6px 11px;
        text-align: center;
        background: ${props => props.theme.colors.primary};
        color: #fff;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }

  .gatsby-image-wrapper {
    display: block;
    overflow: inherit !important;
    picture {
      &::before {
        content: "";
        position: absolute;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.6);
        left: 5px;
        right: 50%;
        bottom: 8px;
        height: 30%;
        z-index: -1;
        border-radius: inherit;
        transform-origin: 0 0;
        transform: skewY(-6deg);
      }
      &::after {
        content: "";
        position: absolute;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.6);
        right: 5px;
        left: 50%;
        bottom: 26px;
        height: 30%;
        z-index: -1;
        border-radius: inherit;
        transform-origin: 0 0;
        transform: skewY(6deg);
      }
    }
  }

  .stat-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .stat-col {
      text-align: center;
      padding: 50px 10px;

      .stat-count {
        color: #fff;
        display: inline-block;
        border-bottom: 1px solid #fff;
        padding: 15px 15px;
        padding-top: 0;
        font-size: 25px;

        &:before {
          content: "✓";
          display: block;
          margin-bottom: 10px;
          color: ${props => props.theme.colors.primary};
        }
      }

      .stat-description {
        color: #fff;
        padding: 5px;
      }
    }
  }
`
