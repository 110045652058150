import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BackgroundInner = styled.div`
  max-width: ${props => props.theme.container.maxWidth};
  margin: 0 auto;
  padding: 50px 0;
`
const BackgroundSection = ({ fluid, content, className }) => (
  <BackgroundImage
    Tag="section"
    fluid={fluid}
    backgroundColor={`#040e18`}
    className={className}
  >
    <BackgroundInner dangerouslySetInnerHTML={{ __html: content }} />
  </BackgroundImage>
)

const StyledBackgroundSection = styled(BackgroundSection)``

export const SectionBackground = ({ node }) => {
  return (
    <StyledBackgroundSection
      fluid={node.acf.photo.localFile.childImageSharp.fluid}
      content={node.content}
    />
  )
}
