import React, { useState } from "react"
import { ShowMoreWrapper, ShowMoreButton, ShowMoreContent } from "./style"

export const ShowMore = ({ children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <ShowMoreWrapper>
      <ShowMoreButton onClick={() => setVisible(!visible)}>
        Czytaj więcej
      </ShowMoreButton>
      <ShowMoreContent
        visible={visible}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </ShowMoreWrapper>
  )
}
