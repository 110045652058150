import React from "react"
import { Wrapper, Inner } from "./style"

export const SectionPrimary = ({ node }) => {
  return (
    <Wrapper>
      <Inner dangerouslySetInnerHTML={{ __html: node.content }} />
    </Wrapper>
  )
}
