import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BackgroundInner = styled.div`
  width: 70%;
  height: auto;
  background: rgb(255 255 255 / 90%);
  padding: 10px;
  text-align: center;
  font-family: ${props => props.theme.font.name.primary};
  color: ${props => props.theme.colors.secondary};
  text-transform: uppercase;
  h2 {
    font-size: 30px;
    font-weight: 300;
  }

  ${props => props.theme.mq.tablet} {
    width: 80%;
    h2 {
      font-size: 20px;
    }
  }
`
const BackgroundSection = ({ fluid, content, className }) => (
  <BackgroundImage
    Tag="section"
    fluid={fluid}
    backgroundColor={`#040e18`}
    className={className}
  >
    <BackgroundInner dangerouslySetInnerHTML={{ __html: content }} />
  </BackgroundImage>
)

const StyledBackgroundSection = styled(BackgroundSection)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60vh;
  background-position: center center;
  background-size: cover;
`

export const SectionSlider = ({ node }) => {
  return (
    <StyledBackgroundSection
      fluid={node.acf.photo.localFile.childImageSharp.fluid}
      content={node.content}
    />
  )
}
