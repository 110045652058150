import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.secondary};
`

export const Inner = styled.div`
  max-width: ${props => props.theme.container.maxWidth};
  margin: 0 auto;
  padding: 20px;
  h2,
  h3,
  h4 {
    margin: 30px 0;
    margin-bottom: 30px;
  }
`
