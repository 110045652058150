import React from "react"
import { graphql } from "gatsby"
import { Layout } from "components/Layout"
import { Section } from "components/Section"

const Index = ({ data }) => {
  return (
    <Layout>
      {data.allWordpressWpSections.edges.map(({ node }) => (
        <Section key={node.id} node={node} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    allWordpressWpSections(sort: { fields: menu_order }) {
      edges {
        node {
          id
          title
          slug
          content
          menu_order
          acf {
            image_location
            show_more
            type
            map_link
            dates
            photo {
              localFile {
                childImageSharp {
                  fixed(height: 200, width: 320) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Index
