import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: ${props => props.theme.container.maxWidth};
  margin: 0 auto;
  padding: 15px;

  & > div {
    text-align: center;
  }
`
