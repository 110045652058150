import React from "react"
import { Wrapper } from "./style"

export const SectionMap = ({ node }) => {
  return (
    <Wrapper>
      <iframe title="Map" loading="lazy" src={node.acf.map_link} />
    </Wrapper>
  )
}
