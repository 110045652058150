import React from "react"
import { Wrapper, Header } from "./style"

export const Timetable = ({ node }) => {
  const count = () => {
    let i = 0
    Object.keys(node.acf).forEach(name => {
      node.acf[name] && i++
    })
    return i
  }
  return (
    <>
      <Header dangerouslySetInnerHTML={{ __html: node.content }} />
      <Wrapper count={count()}>
        {node.acf.monday && (
          <div>
            <h4>Poniedziałek</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.monday }} />
          </div>
        )}
        {node.acf.tuesday && (
          <div>
            <h4>Wtorek</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.tuesday }} />
          </div>
        )}
        {node.acf.wednesday && (
          <div>
            <h4>Środa</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.wednesday }} />
          </div>
        )}
        {node.acf.thursday && (
          <div>
            <h4>Czwartek</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.thursday }} />
          </div>
        )}
        {node.acf.friday && (
          <div>
            <h4>Piątek</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.friday }} />
          </div>
        )}
        {node.acf.saturday && (
          <div>
            <h4>Sobota</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.saturday }} />
          </div>
        )}
        {node.acf.sunday && (
          <div>
            <h4>Niedziela</h4>
            <div dangerouslySetInnerHTML={{ __html: node.acf.sunday }} />
          </div>
        )}
      </Wrapper>
    </>
  )
}
