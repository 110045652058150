import styled, { css } from "styled-components"

export const ShowMoreWrapper = styled.div``

export const ShowMoreButton = styled.button`
  font-family: ${props => props.theme.font.name.primary};
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  padding: 8px 25px;
  background: ${props => props.theme.colors.primary};
  border: 1px solid #dea600;
  border-radius: 5px;
  outline: 0;
`

export const ShowMoreContent = styled.div`
  margin: 15px 10px;
  overflow: hidden;

  height: 0;
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform 0.4s ease-out;

  ${props =>
    props.visible &&
    css`
      height: 100%;
      transform: scaleY(1);
    `}
`
