import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  padding: 15px;

  max-width: ${props => props.theme.container.maxWidth};
  margin: 50px auto;

  ${props =>
    props.reverse &&
    css`
      grid-template-columns: 1fr 2fr;
      .gatsby-image-wrapper {
        grid-row: 1;
      }
    `}

  ${props => props.theme.mq.tablet} {
    display: block;

    .gatsby-image-wrapper {
      width: 100% !important;
    }
  }
`
