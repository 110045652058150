import React from "react"
import Img from "gatsby-image"
import { Wrapper } from "./style"
import { ShowMore } from "./ShowMore"

export const SectionDefault = ({ node }) => {
  return (
    <Wrapper reverse={node.acf.image_location === "left"}>
      <div>
        <div dangerouslySetInnerHTML={{ __html: node.content }} />
        {node.acf.show_more && <ShowMore>{node.acf.show_more}</ShowMore>}
      </div>

      {node.acf.photo && (
        <Img fixed={node.acf.photo.localFile.childImageSharp.fixed} />
      )}
    </Wrapper>
  )
}
