import React from "react"

import { SectionDefault } from "./SectionDefault"
import { SectionPrimary } from "./SectionPrimary"
import { SectionTimetable } from "./SectionTimetable"
import { SectionSlider } from "./SectionSlider"
import { SectionBackground } from "./SectionBackground"
import { SectionMap } from "./SectionMap"
import { SectionContact } from "./SectionContact"
import { SectionRegister } from "./SectionRegister"

import { Wrapper } from "./style"

export const Section = ({ node }) => {
  return (
    <Wrapper id={node.slug}>
      {(() => {
        if (node.acf.type === "default") {
          return <SectionDefault node={node} />
        } else if (node.acf.type === "primary") {
          return <SectionPrimary node={node} />
        } else if (node.acf.type === "timetable") {
          return <SectionTimetable node={node} />
        } else if (node.acf.type === "slider") {
          return <SectionSlider node={node} />
        } else if (node.acf.type === "background") {
          return <SectionBackground node={node} />
        } else if (node.acf.type === "map") {
          return <SectionMap node={node} />
        } else if (node.acf.type === "contact") {
          return <SectionContact node={node} />
        } else if (node.acf.type === "register") {
          return <SectionRegister node={node} />
        }
      })()}
    </Wrapper>
  )
}
