import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Timetable } from "./Timetable"
import { Wrapper } from "./style"

export const SectionTimetable = ({ node }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWordpressWpTimetables(sort: { fields: menu_order }) {
        edges {
          node {
            id
            content
            acf {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: node.content }} />
      {data.allWordpressWpTimetables.edges.map(({ node }) => (
        <Timetable key={node.id} node={node} />
      ))}
    </Wrapper>
  )
}
