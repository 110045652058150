import React, { useState } from "react"
import axios from "axios"
import { useStaticQuery, graphql } from "gatsby"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import { Wrapper, Inner } from "./style"

const FormSchema = Yup.object().shape({
  your_name: Yup.string().required("Pole jest wymagane"),
  your_email: Yup.string()
    .email("Nieprawidłowy email")
    .required("Pole jest wymagane"),
  your_subject: Yup.string().required("Pole jest wymagane"),
  your_message: Yup.string().required("Pole jest wymagane"),
})

const ContactForm = ({ url, node }) => {
  const [resultMsg, setResultMsg] = useState({})
  return (
    <Formik
      initialValues={{
        your_name: "",
        your_email: "",
        your_subject: "",
        your_message: "",
      }}
      validationSchema={FormSchema}
      onSubmit={async (values, actions) => {
        setResultMsg({})
        actions.setSubmitting(true)

        const form = new FormData()
        Object.keys(values).forEach(name => {
          form.set(name, values[name])
        })

        const { data } = await axios.post(url, form)
        actions.setSubmitting(false)

        document &&
          document.querySelector(`#${node.slug}`).scrollIntoView({
            behavior: "smooth",
            block: "start",
          })

        if (data.status === "mail_sent") {
          setResultMsg({
            type: "success",
            msg: "Pomyślnie wysłano wiadomość.",
          })
          actions.resetForm()
        } else {
          setResultMsg({
            type: "error",
            msg: "Wystąpił błąd, spróbuj ponownie.",
          })
        }
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          {resultMsg && resultMsg.msg && (
            <div className={`resultMsg-${resultMsg.type}`}>{resultMsg.msg}</div>
          )}

          <label htmlFor="your_name">Imię i nazwisko</label>
          <Field id="your_name" name="your_name" type="text" />
          {errors.your_name && touched.your_name ? (
            <div className="errorMsg">{errors.your_name}</div>
          ) : null}
          <label htmlFor="your_email">Email</label>
          <Field id="your_email" name="your_email" type="email" />
          {errors.your_email && touched.your_email ? (
            <div className="errorMsg">{errors.your_email}</div>
          ) : null}
          <label htmlFor="your_subject">Temat</label>
          <Field id="your_subject" name="your_subject" type="text" />
          {errors.your_subject && touched.your_subject ? (
            <div className="errorMsg">{errors.your_subject}</div>
          ) : null}
          <label htmlFor="your_message">Treść wiadomości</label>
          <Field
            id="your_message"
            name="your_message"
            component="textarea"
            rows="5"
          />
          {errors.your_message && touched.your_message ? (
            <div className="errorMsg">{errors.your_message}</div>
          ) : null}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? <span>Ładowanie...</span> : <span>Wyślij</span>}
          </button>
        </Form>
      )}
    </Formik>
  )
}

export const SectionContact = ({ node }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            wordpressContactUrl
          }
        }
      }
    `
  )
  return (
    <Wrapper>
      <Inner>
        <ContactForm url={site.siteMetadata.wordpressContactUrl} node={node} />
        <div dangerouslySetInnerHTML={{ __html: node.content }} />
      </Inner>
    </Wrapper>
  )
}
