import React, { useState } from "react"
import axios from "axios"
import { useStaticQuery, graphql } from "gatsby"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import { Wrapper, Inner } from "./style"

const FormSchema = Yup.object().shape({
  date: Yup.string().required("Pole jest wymagane"),
  child_name: Yup.string()
    .min(2, "Za mało znaków")
    .max(32, "Za dużo znaków")
    .required("Pole jest wymagane"),
  child_age: Yup.string()
    .max(32, "Za dużo znaków")
    .required("Pole jest wymagane"),
  your_email: Yup.string()
    .email("Nieprawidłowy email")
    .required("Pole jest wymagane"),
  your_phone: Yup.string()
    .min(6, "Za mało znaków")
    .max(32, "Za dużo znaków")
    .required("Pole jest wymagane"),
  rules: Yup.string().required("Pole jest wymagane"),
})

const RegisterForm = ({ url, node }) => {
  const [resultMsg, setResultMsg] = useState({})
  return (
    <Formik
      initialValues={{
        date: "",
        child_name: "",
        child_age: "",
        your_email: "",
        your_phone: "",
        rules: [],
      }}
      validationSchema={FormSchema}
      onSubmit={async (values, actions) => {
        setResultMsg({})
        actions.setSubmitting(true)

        const form = new FormData()
        Object.keys(values).forEach(name => {
          form.set(name, values[name])
        })

        const { data } = await axios.post(url, form)
        actions.setSubmitting(false)

        document &&
          document.querySelector(`#${node.slug}`).scrollIntoView({
            behavior: "smooth",
            block: "start",
          })

        if (data.status === "mail_sent") {
          setResultMsg({
            type: "success",
            msg: "Pomyślnie wysłano wiadomość.",
          })
          actions.resetForm()
        } else {
          setResultMsg({
            type: "error",
            msg: "Wystąpił błąd, spróbuj ponownie.",
          })
        }
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          {resultMsg && resultMsg.msg && (
            <div className={`resultMsg-${resultMsg.type}`}>{resultMsg.msg}</div>
          )}
          <h4>1. Wybierz termin</h4>
          {node.acf.dates &&
            node.acf.dates
              .split("\n")
              .filter(i => i)
              .map(date => (
                <label htmlFor={`date-${date}`} key={date}>
                  <Field
                    id={`date-${date}`}
                    type="radio"
                    name="date"
                    value={date}
                  />
                  {date}
                </label>
              ))}
          {errors.date && touched.date ? (
            <div className="errorMsg">{errors.date}</div>
          ) : null}

          <h4>3. Dane dziecka</h4>
          <label htmlFor="child_name">Imię dziecka</label>
          <Field id="child_name" name="child_name" type="text" />
          {errors.child_name && touched.child_name ? (
            <div className="errorMsg">{errors.child_name}</div>
          ) : null}
          <label htmlFor="child_age">Wiek</label>
          <Field id="child_age" name="child_age" type="text" />
          {errors.child_age && touched.child_age ? (
            <div className="errorMsg">{errors.child_age}</div>
          ) : null}
          <h4>3. Twoje dane</h4>
          <label htmlFor="your_email">Adres email</label>
          <Field id="your_email" name="your_email" type="email" />
          {errors.your_email && touched.your_email ? (
            <div className="errorMsg">{errors.your_email}</div>
          ) : null}
          <label htmlFor="your_phone">Numer telefonu</label>
          <Field id="your_phone" name="your_phone" type="text" />
          {errors.your_phone && touched.your_phone ? (
            <div className="errorMsg">{errors.your_phone}</div>
          ) : null}
          <label htmlFor="rules">
            <Field id="rules" name="rules" type="checkbox" value="1" />
            <span>
              Wyrażam zgodę na przetwarzanie moich danych osobowych do celów
              marketingowych
            </span>
          </label>
          {errors.rules && touched.rules ? (
            <div className="errorMsg">{errors.rules}</div>
          ) : null}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? <span>Ładowanie...</span> : <span>Wyślij</span>}
          </button>
        </Form>
      )}
    </Formik>
  )
}

export const SectionRegister = ({ node }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            wordpressRegisterUrl
          }
        }
      }
    `
  )
  console.log(node)
  return (
    <Wrapper>
      <Inner>
        <div dangerouslySetInnerHTML={{ __html: node.content }} />
        <RegisterForm
          url={site.siteMetadata.wordpressRegisterUrl}
          node={node}
        />
      </Inner>
    </Wrapper>
  )
}
