import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.secondary};
  padding: 50px 15px;
`

export const Inner = styled.div`
  max-width: ${props => props.theme.container.maxWidth};
  margin: 0 auto;
  color: #fff;

  h2,
  h3,
  h4 {
    color: #fff;
    margin-top: 20px;
  }

  form {
    label {
      display: block;
      margin-top: 15px;
      margin-bottom: 5px;
      cursor: pointer;
    }
    input[type="text"],
    input[type="email"] {
      display: block;
      width: 100%;
      padding: 10px;
      border: 0;
      color: #4a4a4a;
      outline: 0;
      margin-bottom: 15px;
      font-size: 16px;
      border-radius: 0;
    }

    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 10px;
    }

    button {
      background: ${props => props.theme.colors.primary};
      border-radius: 50px;
      padding: 15px 30px;
      border: 0;
      outline: 0;
      margin-top: 15px;
    }

    h4 {
      color: #fff;
    }

    .errorMsg {
      color: #f3c62b;
      font-weight: bold;
    }

    .resultMsg-success {
      background: #39ad00;
      padding: 15px;
      font-weight: bold;
    }

    .resultMsg-error {
      background: #ad0000;
      padding: 15px;
      font-weight: bold;
    }
  }
`
