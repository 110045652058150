import styled from "styled-components"

export const Header = styled.div`
  margin-top: 25px;
  background: whitesmoke;
  border: 1px solid #eaeaea;
  border-bottom: 0;
  padding: 10px;

  * {
    margin: 0 !important;
    text-align: center !important;
  }
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);

  ${props => props.theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${props => props.theme.mq.phone} {
    display: block;
  }

  & > div {
    border: 1px solid #eaeaea;
    margin-right: -1px;
    margin-bottom: 10px;

    h4 {
      font-size: 16px;
      text-align: center;
      border-bottom: 1px solid #eaeaea;
      color: #000;
      padding: 10px;
      background: whitesmoke;
    }
    & > div {
      padding: 10px;
      text-align: center;

      p {
        text-align: center;
      }

      b,
      strong {
        font-weight: 600;
      }
    }
  }
`
